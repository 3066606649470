
import { Component, Vue, Watch } from 'vue-property-decorator'
import CardWithTitle from '@/components/Card/CardWithTitle.vue'
import Table from '@/components/Table/Table.vue'
import FButton from '@/components/Button/FButton.vue'
import Alert from '@/components/Alert/Alert.vue'
import LockedPaymentInfoModal from './LockedPaymentInfoModal.vue'
import DropdownIcon from '@/components/Dropdown/DropdownIcon.vue'
import DropdownItem from '@/components/Dropdown/DropdownItem.vue'
import DropdownLink from '@/components/Dropdown/DropdownLink.vue'
import EmptyBadge from '@/components/Badge/EmptyBadge.vue'
import { TableColumn } from '@/models/TableColumn';
import { ApiService } from '@/services/ApiService';
import { PaymentSettingsDetails } from '@/models/PaymentSettingsDetails';
import { PaymentSetting } from '@/models/PaymentSetting'
import { ProfileService } from '@/services/ProfileService';
import { PaymentSettingsService } from '@/services/PaymentSettingsService'
import { BusyService } from '@/services/BusyService'
import { ToastService } from '@/services/ToastService'
import { i18n } from '@/main'
import { DynamicTranslationService } from '@/services/DynamicTranslationService'

@Component({
  components: {
    CardWithTitle,
    Table,
    FButton,
    Alert,
    DropdownIcon,
    DropdownItem,
    DropdownLink,
    LockedPaymentInfoModal,
    EmptyBadge
  },
  metaInfo: () => ({
    title: DynamicTranslationService.paymentSettingsTitle
  }),
})
export default class PaymentSettings extends Vue {
  data: PaymentSettingsDetails | null = null
  countryLocations: any[] = []

  get tableColumns(): TableColumn[] {
    return [
      { key: 'payoutLocation', label: i18n.t('R.LIT_CCLIPayoutLocations').toString() },
      { key: 'type', label: i18n.t('R.LIT_Type').toString() },
      { key: 'details', label: i18n.t('R.LIT_Details').toString() },
      { key: 'info', label: '' }
    ]
  }

  get enableDirectDeposit() {
    return ProfileService.enableDirectDeposit
  }

  get directDepositStatus() {
    return PaymentSettingsService.directDepositStatus;
  }

  get paymentSettings() {
    return this.data?.paymentSettings;
  }

  get payoutLocations() {
    return [
      {
        key: 'NA',
        value: 'North America'
      },
      {
        key: 'UKIE',
        value: 'UK & Ireland'
      },
      {
        key: 'AP',
        value: 'Asia Pacific'
      },
      {
        key: 'AF',
        value: 'Africa'
      },
      {
        key: 'EU',
        value: 'Mainland Europe'
      },
      {
        key: 'KR',
        value: 'Korea'
      }
    ]
  }

  getPayoutLocationValues(keys: any) {
    return keys.map((key: string) =>
      this.payoutLocations.find(location => location.key === key)?.value
    ).filter((value: any) => value !== undefined).join(', ');
  }

  async mounted() {
    BusyService.showBusy();

    if (!ProfileService.profile?.org) {
      throw Error('Organization model missing');
    }

    this.data = await ApiService.post('/api/GetPaymentSettings', { organizationId: ProfileService.profile.org.id });
    this.countryLocations = await ApiService.post('/api/GetCountryDetails');

    if(this.paymentSettings){
      const removePayments = this.paymentSettings.filter(p => p.payoutLocations.length === 0);

      if (removePayments && removePayments.length > 0) {
        for (const paymentSetting of removePayments) {
          await ApiService.post('/api/DeletePaymentSetting', { paymentSettingId: paymentSetting.paymentSettingId });
          ToastService.successToast(this.$t('R.LIT_PaymentMethodDeleteSuccessfully').toString());
        }
        this.data = await ApiService.post('/api/GetPaymentSettings', { organizationId: ProfileService.profile.org.id });
      }
    }
    BusyService.hideBusy();
  }

  async refreshPaymentSettings() {
    BusyService.showBusy();
    try {
      if (ProfileService.profile?.org) {
        this.data = await ApiService.post('/api/GetPaymentSettings', { organizationId: ProfileService.profile.org.id });
      }
    } catch (error) {
      console.error('Failed to refresh payment settings:', error);
    } finally {
      BusyService.hideBusy();
    }
  }

  showLockedPaymentInfoModal() {
    (this.$refs.lockedPaymentInfoModal as LockedPaymentInfoModal).show();
  }

  paymentCountryName(countryCode: string) {
    return this.countryLocations.find((country: any) => country.countryCode === countryCode)?.countryName;
  }

  @Watch('paymentSettings.length')
  async refreshPayments() {
    await PaymentSettingsService.getDirectDepositStatus();
    this.refreshPaymentSettings();
  }

}
