
import { Component, Vue } from 'vue-property-decorator'
import Modal from '@/components/Modal/Modal.vue'
@Component({
  components: {
    Modal,
  }
})

export default class LockedPaymentInfoModal extends Vue {
  init = false;
  completed = false;

  get paymentLockedMessage() {
    return this.$t('R.MES_PaymentIsLockedMessage');
  }
  async show() {
    (this.$refs.modal as Modal).show();
  }

  onClose() {
    (this.$refs.modal as Modal).hide();
    this.init = false;
  }
}
